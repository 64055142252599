@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base { */
:root {
	/* Theme agnostic colors */
	--highlight-color: #FF6729;
	--medlight-color: #C8440C;
	--lowlight-color: #C8440C;

	/* Light mode */
	--bg-base-color: #ebecf0;
	--bg-middleground-color: #f4f5f8;
	--bg-foreground-color: #ffffff;
	--text-primary-color: #1d2024;
	--text-secondary-color: #6e737c;
	--text-muted-color: #90939c;
	--text-button-alt: #ffffff;
	--alt-color: #d9d9e4;
	--bg-line-divider-color: #d6e0ed;
	--bg-button-alt: #000000;
}

.dark {
	--bg-base-color: #1A1A1A;
	--bg-middleground-color: #2A2A2A;
	--bg-foreground-color: #2A2A2A;
	--text-primary-color: #eeeeee;
	--text-secondary-color: #a6a9b6;
	--text-muted-color: #818388;
	--text-button-alt: #000000;
	--alt-color: #3F3F3F;
	--bg-line-divider-color: #5f6368;
	--bg-button-alt: #ffffff;
}
/* } */
